import React, { FC } from 'react';
import { SRWImage } from '../../components';
import classNames from 'classnames';
import styles from './FullWidthImage.module.scss';

type Props = { slice: any };

const FullWidthImage: FC<Props> = ({ slice }) => {
  const isDefault = slice.variation !== 'hero_image';
  const isDarkMode = slice.variation.endsWith('dark') ?? false;
  return (
    <div
      className={classNames('wide', {
        dark: isDarkMode,
      })}
    >
      <section
        className={classNames(styles.section, {
          [styles.limitcontent]: isDefault,
          [styles.content]: !isDefault,
        })}
      >
        {slice.primary.image && (
          <SRWImage
            image={slice.primary.image}
            minWidth={{ xs: '100%', md: isDefault ? '1156px' : '100%' }}
            minHeight="550px"
            type="cover"
          />
        )}
      </section>
    </div>
  );
};

export default FullWidthImage;
