import { Box, Typography } from '@mui/material';
import classNames from 'classnames';
import React, { FC } from 'react';
import { SRW_COLOR_PRIMARY } from '../../theme';
import styles from './ProjectTimeline.module.scss';

type Props = { slice: any };

const ProjectTimeline: FC<Props> = ({ slice }) => {
  const isDarkMode = slice.variation.endsWith('dark');
  return (
    <Box
      position="relative"
      className={classNames('wide', {
        dark: isDarkMode,
      })}
    >
      <Box
        component="section"
        display="flex"
        flexDirection={{xs: "column", sm:"row"}}
        width="100%"
        justifyItems="space-between"
        className={styles.row}
        pb={{xs: 4, sm: 8}}
      >
        {slice.items.map((item: any, i: number) => {
          const columns = item.time > 10 ? 3 : item.time > 5 ? 2 : 1;
          const shade = ((i / slice.items.length) * 200 + 50).toString(16);
          return (
            <Box
              key={`item-${i}`}
              display="flex"
              flexDirection="column"
              flexGrow={columns}
              bgcolor={`${SRW_COLOR_PRIMARY}${shade}`}
              p={2}
              className={styles.card}
            >
              <Box>
                <Typography variant="body3" component="p">
                  {item.text}
                </Typography>
              </Box>
              <Box textAlign="right" mt={{xs: columns * 3 - 1, sm: 2}}>
                <Typography variant="body3">
                  {item.time} {slice.primary.timeframe}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ProjectTimeline;
