import React, { FC, Fragment } from 'react';
import { RichText, SRWImage } from '../../components';
import { Box, Typography } from '@mui/material';
import classNames from 'classnames';
import styles from './HeroTitle.module.scss';
import { FONT_COLOR_WHITE, SRW_COLOR_PRIMARY } from '../../theme';
import { useIsMobile } from '../../utils/helpers';
import { PrismicText } from '@prismicio/react';

type Props = { slice: any };

const HeroTitle: FC<Props> = ({ slice }) => {
  const isDarkMode = slice.variation.endsWith('dark');
  const isCaseStudy = slice.variation.includes('case_study');
  const isHeroText = slice.variation.includes('hero_text');
  const isMobile = useIsMobile();

  return (
    <Fragment>
      <Box
        className={classNames('wide', {
          dark: isDarkMode,
        })}
      >
        <Box
          display="flex"
          alignItems="center"
          component="section"
          className={classNames('fullHeight', styles.section, {
            [styles.dark]: isDarkMode,
          })}
        >
          <Box zIndex={1}>
            {slice.primary.type && (
              <Typography variant="caption">{slice.primary.type}</Typography>
            )}
            <div className={styles.title}>
              {slice.primary.title && <RichText field={slice.primary.title} />}
            </div>
            {slice.primary.subtitle && (
              <div className={styles.subtitle}>
                <Typography variant="subtitle1">
                  <PrismicText field={slice.primary.subtitle} />
                </Typography>
              </div>
            )}
            {isCaseStudy && slice.items && slice.items.length > 1 && (
              <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                justifyContent="space-between"
                className={styles.items}
                mt={1}
                mb={15}
              >
                {slice.items.map((item: any, i: number) => (
                  <Box
                    key={`item-${i}`}
                    display="flex"
                    flexDirection="column"
                    flex={1}
                    pr={4}
                  >
                    <Box>
                      <Typography
                        variant="body5"
                        component="p"
                        className={styles.itemTitle}
                      >
                        <PrismicText field={item.title} />
                      </Typography>
                    </Box>
                    <Box className={styles.itemText}>
                      <Typography variant="body2" component="p">
                        <PrismicText field={item.text} />
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {isHeroText && (
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          className={styles.heroTextBox}
        >
          {slice.primary.text && (
            <Box
              width={{ xs: '100%', sm: 'min(60%, 800px)' }}
              bgcolor={SRW_COLOR_PRIMARY}
              color={FONT_COLOR_WHITE}
              zIndex={2}
              py={{ xs: 5, SM: 8 }}
              pr={{ xs: 4, sm: 8 }}
              pl={{ xs: 4, sm: 12 }}
            >
              <RichText field={slice.primary.text} />
            </Box>
          )}
          {slice.primary.image && slice.primary.image.url && !isMobile && (
            <Box width={{ sm: '40%' }}>
              <SRWImage
                image={slice.primary.image}
                width="100%"
                height="550px"
              />
            </Box>
          )}
        </Box>
      )}
      {!isHeroText &&
        slice.primary.image &&
        slice.primary.image.url &&
        !isMobile && (
          <Box
            className={classNames({
              dark: !isDarkMode,
            })}
            mb="-12vh"
          >
            <Box component="section" position="relative" top="-12vh">
              <SRWImage
                image={slice.primary.image}
                width="100%"
                maxWidth="1156px"
                height="550px"
                type="cover"
              />
            </Box>
          </Box>
        )}
    </Fragment>
  );
};

export default HeroTitle;
