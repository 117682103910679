import { Box } from '@mui/material';
import React, { FC, Fragment } from 'react';
import { Position } from '../../components';

// must include slice for compactible
type Props = { slice: any; context: { positions: any[] } };

const PositionList: FC<Props> = ({ context }) => {
  const { positions } = context;

  return (
    <section>
      <Box
        display="flex"
        flexDirection="column"
        alignContent="center"
        justifyContent="space-between"
      >
        {positions.map((position: any, index: number) => (
          <Fragment key={`positions-${index}`}>
            <Position position={position.data} />
          </Fragment>
        ))}
      </Box>
    </section>
  );
};

export default PositionList;
