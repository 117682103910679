import React, { FC, useEffect, useState } from 'react';
import { PrismicRichText } from '@prismicio/react';
import { Box, Typography } from '@mui/material';
import { SRWIcon, SRWImage } from '../../components';
import { PrismicLink } from '@prismicio/react';
import theme from '../../theme';

import styles from './FooterLink.module.scss';
import { createClient } from '../../prismicio';

type Props = { slice: any };

const FooterLink: FC<Props> = ({ slice }) => {
  const [link, setLink] = useState<any>();
  useEffect(() => {
    if (slice.primary.link) {
      const getProject = async () => {
        const client = createClient();
        const project =
          (await client.getByUID('project', slice.primary.link.uid)) ?? null;
        if (project) {
          setLink(project);
        }
      };
      getProject();
    }
  }, [slice.primary.link]);
  return link ? (
    <Box
      className="dark wide"
      mt={{ xs: 3, sm: 6, md: 12 }}
      pt={{ xs: 3, sm: 6, md: 12 }}
    >
      <Box component="section" className="narrow">
        <PrismicLink href={link.url} className={styles.link}>
          <Box display="flex" flexDirection="column">
            <Box textAlign="left" ml="auto" mb={3}>
              <Typography variant="h3" component="span">
                See how we did this{'  '}
              </Typography>
              <Typography variant="h3" component="span" color="primary">
                <SRWIcon name="arrowRight" color="primary" />
              </Typography>
            </Box>
            <Box mb={3}>
              <SRWImage
                image={link.data.image}
                maxWidth="100%"
                minHeight="368px"
                type="cover"
                flexGrow={1}
              />
            </Box>
            <Box>
              <Typography variant="button">{link.data.type}</Typography>
            </Box>
            <Box>
              <PrismicRichText field={link.data.title} />
            </Box>
            <Box>
              <PrismicRichText field={link.data.text} />
            </Box>
          </Box>
        </PrismicLink>
      </Box>
      <Box
        component="section"
        bgcolor={theme.palette.text.secondary}
        width="100%"
        height="2px"
        mt={{ xs: 3, sm: 6, md: 12 }}
      />
    </Box>
  ) : null;
};

export default FooterLink;
