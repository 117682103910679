import React, { FC } from 'react';
import { RichText, SRWImage } from '../../components';
import Box from '@mui/material/Box';
import { SRW_COLOR_PRIMARY, FONT_COLOR_WHITE } from '../../theme';
import styles from './HeroQuote.module.scss';
import quote from '../../../public/quote.svg';
import Image from 'next/image';
import classNames from 'classnames';
import { Typography } from '@mui/material';
import { SRW_FONT_REGULAR } from '../../theme/fonts';
import { PrismicText } from '@prismicio/react';

type Props = { slice: any };

const HeroQuote: FC<Props> = ({ slice }) => {
  const isDarkMode = slice.variation.endsWith('dark');
  return (
    <Box
      pt={10}
      className={classNames('wide', {
        dark: isDarkMode,
      })}
    >
      <Box
        width={{ xs: '100%', sm: '60%' }}
        bgcolor={SRW_COLOR_PRIMARY}
        color={FONT_COLOR_WHITE}
        zIndex={2}
        py={{ xs: 5, sm: 8 }}
        pr={{ xs: 4, sm: 8 }}
        pl={{ xs: 4, sm: 12 }}
      >
        {slice.primary.image && (
          <Box
            width="160px"
            height="160px"
            mt={{ xs: -15, sm: -18 }}
            mb={2}
            mx={{ xs: 'auto', sm: 0 }}
          >
            <SRWImage
              image={slice.primary.image}
              minHeight="160px"
              className={styles.image}
            />
            <Box mt={-5} zIndex='101' position='relative'>
              <Image src={quote} alt="quote mark" width="48" height="38"/>
            </Box>
          </Box>
        )}

        {slice.primary.text && (
          <Typography variant="h5" mb={2}>
            <PrismicText field={slice.primary.text} />
          </Typography>
        )}
        {slice.primary.name && <RichText field={slice.primary.name} />}
        {slice.primary.position && (
          <Typography variant="h6" fontWeight={SRW_FONT_REGULAR}>
            <PrismicText field={slice.primary.position} />
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default HeroQuote;
