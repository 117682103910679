// This file is generated by Prismic local builder. Update with care!

export { default as Blockquote } from './Blockquote'
export { default as CardLine } from './CardLine'
export { default as FooterLink } from './FooterLink'
export { default as FullWidthImage } from './FullWidthImage'
export { default as HeroQuote } from './HeroQuote'
export { default as HeroTitle } from './HeroTitle'
export { default as HomePage } from './HomePage'
export { default as HowWeWork } from './HowWeWork'
export { default as ImageCarousel } from './ImageCarousel'
export { default as ImageGridLandscapeFirst } from './ImageGridLandscapeFirst'
export { default as ImageText } from './ImageText'
export { default as ImageTextCarousel } from './ImageTextCarousel'
export { default as LogoLine } from './LogoLine'
export { default as PositionList } from './PositionList'
export { default as ProjectStats } from './ProjectStats'
export { default as ProjectTimeline } from './ProjectTimeline'
export { default as TeamMemberGrid } from './TeamMemberGrid'
export { default as Test } from './Test'
export { default as TestTitle } from './TestTitle'
export { default as TitleText } from './TitleText'

import dynamic from 'next/dynamic';

export const components = {
  block_quote: dynamic(() => import('./Blockquote')),
  card_line: dynamic(() => import('./CardLine')),
  footer_link: dynamic(() => import('./FooterLink')),
  full_width_image: dynamic(() => import('./FullWidthImage')),
  hero_quote: dynamic(() => import('./HeroQuote')),
  hero_title: dynamic(() => import('./HeroTitle')),
  home_page: dynamic(() => import('./HomePage')),
  how_we_work: dynamic(() => import('./HowWeWork')),
  image_carousel: dynamic(() => import('./ImageCarousel')),
  image_grid_landscape_first: dynamic(() =>
    import('./ImageGridLandscapeFirst')
  ),
  image_text: dynamic(() => import('./ImageText')),
  image_text_carousel: dynamic(() => import('./ImageTextCarousel')),
  logo_line: dynamic(() => import('./LogoLine')),
  position_list: dynamic(() => import('./PositionList')),
  project_stats: dynamic(() => import('./ProjectStats')),
  project_timeline: dynamic(() => import('./ProjectTimeline')),
  team_member_grid: dynamic(() => import('./TeamMemberGrid')),
  test: dynamic(() => import('./Test')),
  test_title: dynamic(() => import('./TestTitle')),
  title_text: dynamic(() => import('./TitleText')),
};
