import React, { FC, Fragment, createRef } from 'react';
import { Grid, Box } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import {
  CarouselControls,
  HorizontalLine,
  RichText,
  SRWImage,
} from '../../components';
import classNames from 'classnames';

type Props = { slice: any };

const ImageTextCarousel: FC<Props> = ({ slice }) => {
  const isDarkMode = slice.variation.endsWith('dark');
  const slide1Ref = createRef<Splide>();
  const slide2Ref = createRef<Splide>();

  return (
    <Box
      className={classNames({
        dark: isDarkMode,
      })}
      component="section"
    >
      <Grid container columnSpacing={{ sm: 6 }} rowSpacing={{ xs: 6 }}>
        <Grid item sm={6} xs={12}>
          <Box className="carousel">
            <Splide
              options={{
                cover: true,
                type: 'loop',
                pagination: false,
                fixedHeight: 640,
                width: '100%',
              }}
              ref={slide1Ref}
              onMove={(_, newIndex: number) => slide2Ref.current?.go(newIndex)}
              renderControls={() => (
                <div className="splide__arrows">
                  <CarouselControls
                    leftArrowClasses={['splide__arrow', 'splide__arrow--prev']}
                    rightArrowClasses={['splide__arrow', 'splide__arrow--next']}
                  />
                </div>
              )}
            >
              {slice.items.map((item: any, i: number) => (
                <Fragment key={i.toString()}>
                  <SplideSlide>
                    {
                      <SRWImage
                        image={item.image}
                        type="cover"
                        minHeight={{ xs: '640px' }}
                      />
                    }
                  </SplideSlide>
                </Fragment>
              ))}
            </Splide>
          </Box>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Box className="carousel">
            <Splide
              options={{ arrows: false }}
              ref={slide2Ref}
              onMove={(_, newIndex: number) => slide1Ref.current?.go(newIndex)}
              onPaginationMounted={(_, data) => {
                data.list.classList.add('splide__pagination--custom');
              }}
            >
              {slice.items.map((item: any, idx: number) => (
                <Fragment key={idx.toString()}>
                  <SplideSlide>
                    {item.title && (
                      <Box>
                        <RichText field={item.title} />
                        <HorizontalLine />
                      </Box>
                    )}
                    {item.subtitle && (
                      <Box pb={2}>
                        <RichText field={item.subtitle} />
                      </Box>
                    )}
                    {item.text && (
                      <Box pb={4}>
                        <RichText field={item.text} />
                      </Box>
                    )}
                  </SplideSlide>
                </Fragment>
              ))}
            </Splide>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ImageTextCarousel;
