import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import classNames from 'classnames';
import { isValidIcon, SRWIcon } from '../../components';
import styles from './ProjectStats.module.scss';
import { FONT_COLOR_WHITE } from '../../theme';

type Props = { slice: any };

const ProjectStats: FC<Props> = ({ slice }) => {
  const isDarkMode = slice.variation.endsWith('dark');
  return (
    <Box
      position="relative"
      className={classNames('wide', {
        dark: isDarkMode,
      })}
    >
      <Box
        component="section"
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignContent="center"
        justifyContent="space-between"
        className={styles.row}
        py={{ xs: 3, md: 5 }}
      >
        {slice.items.map((item: any, i: number) => (
          <Box
            key={`item-${i}`}
            display="flex"
            flexDirection="column"
            flexGrow={1}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {item.icon && isValidIcon(item.icon) && (
              <Box
                display="flex"
                justifyContent={'center'}
                alignItems={'center'}
                className={classNames(styles.icon)}
                textAlign={'center'}
                width="110px"
                height="110px"
              >
                <SRWIcon
                  size={'4x'}
                  color={FONT_COLOR_WHITE}
                  name={item.icon}
                />
              </Box>
            )}
            <Box textAlign="center" py={2}>
              <Typography variant="h1" component="p">
                {item.number}
              </Typography>
            </Box>
            <Box textAlign="center" mb={{ xs: 3, sm: 1 }}>
              <Typography variant="body3" component="p">
                {item.text}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ProjectStats;
