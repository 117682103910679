import React, { FC } from 'react';
import { SRWImage } from '../../components';
import { Box } from '@mui/material';
import classNames from 'classnames';

type Props = { slice: any };

const ImageGrid: FC<Props> = ({ slice }) => {
  const isLandscapeFirst = slice.variation.includes('landscape');
  const isDarkMode = slice.variation?.endsWith('dark') ?? false;
  return (
    <div
      className={classNames('wide', {
        dark: isDarkMode,
      })}
    >
      <section>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={{
            xs: isLandscapeFirst ? 'column' : 'column-reverse',
            sm: isLandscapeFirst ? 'row' : 'row-reverse',
          }}
          py={2}
        >
          {slice.primary.landscape && (
            <SRWImage
              image={slice.primary.landscape}
              type="cover"
              minHeight={{ xs: '224px', sm: '520px' }}
              maxWidth={{ xs: '100%', sm: '66%' }}
              mt={{ xs: !isLandscapeFirst ? 2 : 0, sm: 0 }}
            />
          )}
          {slice.primary.portrait && (
            <SRWImage
              image={slice.primary.portrait}
              type="cover"
              minHeight={{ xs: '463px', sm: '520px' }}
              maxWidth={{ xs: '100%', sm: '32%' }}
              mt={{ xs: isLandscapeFirst ? 2 : 0, sm: 0 }}
            />
          )}
        </Box>
      </section>
    </div>
  );
};

export default ImageGrid;
