import { Box, Grid } from '@mui/material';
import { PrismicLink } from '@prismicio/react';
import React, { FC } from 'react';
import { HorizontalLine, RichText } from '../../components';
import classNames from 'classnames';
import styles from './TitleText.module.scss';
import { useIsMobile } from '../../utils/helpers';

type Props = { slice: any };

const TitleText: FC<Props> = ({ slice }) => {
  const isDarkMode = slice.variation.endsWith('dark');
  const mobile = useIsMobile();
  const isLeft = !mobile && slice.variation.includes('left');
  const isRight = !mobile && slice.variation.includes('right');
  const centre = slice.primary.centre || false;

  const isDefault = !isLeft && !isRight;

  // add styling for slices with or without a title
  const hasTitle =
    !!slice.primary.title &&
    slice.primary.title.length > 0 &&
    !!slice.primary.title[0].text;

  const hasText = slice.primary.text && slice.primary.text.length > 0;

  const onlyHasTitle =
    hasTitle && !hasText && !slice.primary.label && !slice.primary.link.url;

  return (
    <div
      className={classNames('titleTextSlice', {
        dark: isDarkMode,
        light: !isDarkMode, // only to control if margins should be removed
      })}
    >
      <section
        className={classNames(styles.section, {
          [styles.onlyTitle]: onlyHasTitle,
          [styles.full]: !onlyHasTitle,
          [styles.isLeft]: isLeft,
          [styles.isRight]: isRight,
        })}
      >
        <Grid
          container
          spacing={{ sm: isDefault ? 0 : 2 }}
          className={classNames(styles.container, {
            [styles.base]: isDefault,
          })}
          direction={isRight ? 'row-reverse' : 'row'}
        >
          {hasTitle && (
            <Grid item xs={12} sm={isDefault ? 12 : 6}>
              <Box
                className={classNames(styles.title, {
                  [styles.center]: isDefault && !mobile,
                  [styles.whiteText]: isDarkMode,
                })}
              >
                <RichText field={slice.primary.title} />
              </Box>
              <Box>
                <HorizontalLine center={isDefault && !mobile} />
              </Box>
            </Grid>
          )}
          {hasText && (
            <Grid item xs={12} sm={isDefault ? 12 : 6}>
              <Box
                justifySelf="left"
                className={classNames({
                  [styles.center]: isDefault && centre,
                  [styles.whiteText]: isDarkMode,
                })}
              >
                <RichText field={slice.primary.text} />
              </Box>
            </Grid>
          )}
        </Grid>
        {slice.primary.label && slice.primary.link && (
          <Box textAlign="center" paddingTop={6}>
            <PrismicLink
              href={slice.primary.link.url}
              className={classNames(styles.link, {
                [styles.whiteText]: isDarkMode,
              })}
            >
              {slice.primary.label}
            </PrismicLink>
          </Box>
        )}
      </section>
    </div>
  );
};

export default TitleText;
