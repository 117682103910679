import React, { FC, Fragment } from 'react';
import classNames from 'classnames';
import { SRWImage } from '../../components';
import { Box } from '@mui/material';

type Props = { slice: any };

const LogoLine: FC<Props> = ({ slice }) => {
  const isDarkMode = slice.variation.endsWith('dark');
  return (
    <div
      className={classNames('wide', {
        dark: isDarkMode,
      })}
    >
      <section>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignContent="center"
          justifyContent="space-between"
          maxWidth="1156px"
        >
          {slice.items &&
            slice.items.map((item: any, index: number) => (
              <Fragment key={`logo-${index}`}>
                <SRWImage image={item.image} my={8} minHeight="25px" />
              </Fragment>
            ))}
        </Box>
      </section>
    </div>
  );
};

export default LogoLine;
