import React, {
  FC,
  Fragment,
  MutableRefObject,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { TransitionStatus } from 'react-transition-group';
import {
  HorizontalLine,
  RichText,
  SRWIcon,
  isValidIcon,
} from '../../components';
import Image from 'next/image';
import { PrismicLink, PrismicText } from '@prismicio/react';
import { Box, Grid, Typography } from '@mui/material';
import classNames from 'classnames';
import styles from './HomePage.module.scss';
import { SRW_COLOR_PRIMARY } from '../../theme';
import { useIsMobile } from '../../utils/helpers';

type Props = {
  slice: any;
  context: {
    ref: MutableRefObject<null>;
    state: TransitionStatus;
    belowSlideRendered: boolean;
    belowSlideOpacity: boolean;
    hijackScroll: boolean;
    finalDotActive: boolean;
    setFinalDotActive: (value: boolean) => void;
  };
};

const HomePage: FC<Props> = ({ slice, context }) => {
  const {
    ref,
    state,
    belowSlideRendered,
    belowSlideOpacity,
    hijackScroll,
    finalDotActive,
    setFinalDotActive,
  } = context;

  const isDefault = useMemo(
    () => slice.variation === 'default-slice',
    [slice.variation]
  );
  const finalSlideIndex = useMemo(
    () => slice.primary.totalSteps - 1,
    [slice.primary.totalSteps]
  );
  const isFinalSlide = useMemo(
    () => slice.primary.stepNumber === finalSlideIndex,
    [finalSlideIndex, slice.primary.stepNumber]
  );
  const isMobile = useIsMobile();
  const isTablet = useIsMobile('md');

  const linksPageRef = useRef<HTMLDivElement>(null);

  const image = useCallback(
    (state: TransitionStatus) => {
      return (
        <Grid
          id={`image-${slice.primary.stepNumber}`}
          item
          xs={12}
          md={6}
          display="flex"
          justifyContent="center"
          className={classNames(styles.image, {
            [styles.entering]: state === 'entering',
            [styles.entered]: state === 'entered',
            [styles.exited]: state === 'exiting',
          })}
        >
          <Box className={styles.imageContainer} mb={{ xs: 4, md: 0 }}>
            {!isDefault && (
              <div
                className={classNames(styles.exhaustFlame, {
                  [styles.exhaustFlameShown]:
                    belowSlideOpacity || !hijackScroll,
                })}
              />
            )}
            {slice.primary.image && (
              <Image
                src={slice.primary.image.url}
                alt={slice.primary.image.alt}
                width={slice.primary.image.dimensions.width}
                height={slice.primary.image.dimensions.height}
                style={{ position: 'relative', zIndex: 101 }}
              />
            )}
          </Box>
        </Grid>
      );
    },
    [
      belowSlideOpacity,
      hijackScroll,
      isDefault,
      slice.primary.image,
      slice.primary.stepNumber,
    ]
  );

  const title = useCallback(
    (state: TransitionStatus) => {
      return (
        <Grid
          id={`title-${slice.primary.stepNumber}`}
          item
          xs={12}
          md={6}
          display="flex"
          alignItems="center"
          className={classNames(styles.title, {
            [styles.entering]: state === 'entering',
            [styles.entered]: state === 'entered',
            [styles.exited]: state === 'exiting',
          })}
        >
          <Box display="flex" flexDirection="column" maxWidth="662px">
            {slice.primary.title && (
              <Box>
                <RichText field={slice.primary.title} />
              </Box>
            )}
            {slice.primary.subtitle && (
              <Box mt={{ xs: 2, md: 3 }}>
                <Typography variant="subtitle1">
                  <PrismicText field={slice.primary.subtitle} />
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      );
    },
    [slice.primary.stepNumber, slice.primary.subtitle, slice.primary.title]
  );

  const links = useCallback(
    (state: TransitionStatus): React.ReactNode => {
      // baseline of cloud blobs across the screen
      const staticClouds = Array.apply(null, Array(isMobile ? 10 : 30)).map(
        function () {}
      );
      // 3 blobs stay inline with the rocket, so a more dramatic cloud is always under the thrusters
      const movingLeftClouds = Array.apply(null, Array(3)).map(function () {});
      // 3 blobs form a hill against the right side of the screen, no matter the screen size
      const staticRightClouds = Array.apply(null, Array(2)).map(function () {});
      // several growing and fading blobs dotted along the screen to make it feel less uniform
      const animatedClouds = Array.apply(null, Array(5)).map(function () {});

      return (
        <Grid item xs={12}>
          <Grid
            container
            direction="column"
            className={classNames(styles.linkPageContainer)}
          >
            {isTablet && title('entered')}

            <Grid item>
              {slice.primary.title && (
                <Fragment>
                  <Box
                    className={styles.titleLinks}
                    mt={{ xs: 10, md: 6 }}
                    mb={3}
                  >
                    <Typography variant="h3" color="dark">
                      Find out more
                    </Typography>
                  </Box>
                  <Box mb={5.5}>
                    <HorizontalLine center />
                  </Box>
                </Fragment>
              )}
            </Grid>
            <Grid item className={styles.pages}>
              {slice.items.map((card: any, index: number) => (
                <PrismicLink
                  href={card.link?.url || '/madness'}
                  key={`${card.step}-${index}`}
                  className={styles.card}
                >
                  <Box mb={2}>
                    {card.icon && isValidIcon(card.icon) && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        className={styles.icon}
                      >
                        <SRWIcon
                          size={'lg'}
                          color={SRW_COLOR_PRIMARY}
                          name={card.icon}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box mb={2}>
                    {card.title && <RichText field={card.title} />}
                  </Box>
                  <Box mb={3}>
                    {card.text && (
                      <Typography variant="body2">
                        <PrismicText field={card.text} />
                      </Typography>
                    )}
                  </Box>
                  <Box className={styles.link}>
                    <Typography variant={'body2'} component={'span'} mr={1}>
                      <strong>Find out more </strong>{' '}
                    </Typography>
                    <SRWIcon name={'arrowRight'} />
                  </Box>
                </PrismicLink>
              ))}
            </Grid>
          </Grid>
          <div
            className={classNames(styles.exhaustFumes, {
              [styles.entering]: state === 'entering',
              [styles.entered]: state === 'entered',
              [styles.exited]: state === 'exiting',
            })}
          >
            <div className={styles.staticClouds}>
              {staticClouds.map((_c, i) => (
                <div className={styles.staticCloud} key={`static-${i}`} />
              ))}
              <div
                className={classNames(styles.whiteBar, styles.staticClouds)}
              />
            </div>
            {!isMobile && (
              <Fragment>
                <div className={styles.movingLeftClouds}>
                  {movingLeftClouds.map((_c, i) => (
                    <div
                      className={styles.movingLeftCloud}
                      key={`moving-left-${i}`}
                    />
                  ))}
                </div>
                <div className={styles.staticRightClouds}>
                  {staticRightClouds.map((_c, i) => (
                    <div
                      className={styles.staticRightCloud}
                      key={`static-right-${i}`}
                    />
                  ))}
                </div>
                <div className={styles.animatedClouds}>
                  {animatedClouds.map((_c, i) => (
                    <div
                      className={styles.animatedCloud}
                      key={`animated-${i}`}
                    />
                  ))}
                </div>
              </Fragment>
            )}
          </div>
        </Grid>
      );
    },
    [isMobile, isTablet, slice.items, slice.primary.title, title]
  );

  /**
   * Renders a dot for each slide, plus a "fake" dot for the find out
   * more section at the bottom of the final slide. clicking this dot
   * goes to the final slide then scrolls down the page.
   * @returns
   */
  const steps = useCallback(() => {
    const onFinalDotClick = () => {
      const scrollToFindOutMore = () => {
        if (!linksPageRef.current) {
          return;
        }

        // scrolling to the top of the `linksPage` box goes slightly
        // too far down the page, so we offset it by a minus amount
        const yOffset = -110;
        const boundingBox = linksPageRef.current.getBoundingClientRect();
        const y = boundingBox.top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      };

      // set the "find out more" dot to active. we handle this
      // differently because it's not another slide, so is a "fake" dot
      setFinalDotActive(true);

      if (isFinalSlide) {
        scrollToFindOutMore();
      } else {
        // go to the final slide before scrolling
        slice.goToSlice(finalSlideIndex);
        // wait until the slide navigation is complete before scrolling
        setTimeout(() => {
          scrollToFindOutMore();
        }, 1000);
      }
    };

    return (
      <Box className={styles.step}>
        <Box className={styles.stepContainer}>
          <div className={styles.stepLine} />
          <ul>
            {/* slide dots */}
            {Array.from(Array(slice.primary.totalSteps).keys()).map(
              (_key, index) => {
                // the final dot is a "fake" dot, so we need to not
                // make the regular ones active if that is active
                const dotActive =
                  index === slice.primary.stepNumber && !finalDotActive;

                const onDotClick = () => {
                  // ensure the "find out more" dot is not active, since we've clicked another one
                  if (finalDotActive) {
                    setFinalDotActive(false);
                  }

                  // scroll back up before changing, incase the user was
                  // not at the top of the page, to avoid a sudden jolt
                  if (isFinalSlide && window.scrollY > 0) {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }

                  // go to the new slide
                  slice.goToSlice(index);
                };

                return (
                  <li key={`${slice.primary.step}-${index}`}>
                    <Typography
                      variant={'body2'}
                      fontWeight="medium"
                      onClick={onDotClick}
                      className={classNames(styles.stepLabel, {
                        [styles.active]: dotActive,
                      })}
                    >
                      {slice.primary.titles[index]}
                    </Typography>
                    <span
                      className={classNames(styles.stepDot, {
                        [styles.stepDotActive]: dotActive,
                      })}
                      onClick={onDotClick}
                    >
                      <span className={styles.stepDotInner}>&nbsp;</span>
                    </span>
                  </li>
                );
              }
            )}

            {/* fake dot for the find out more section */}
            <li key={`${slice.primary.step}-${slice.primary.totalSteps}`}>
              <Typography
                variant={'body2'}
                onClick={onFinalDotClick}
                className={classNames(styles.stepLabel, {
                  [styles.active]: finalDotActive,
                })}
              >
                More
              </Typography>
              <span
                className={classNames(styles.stepDot, {
                  [styles.stepDotActive]: finalDotActive,
                })}
                onClick={onFinalDotClick}
              >
                <span className={styles.stepDotInner}>&nbsp;</span>
              </span>
            </li>
          </ul>
        </Box>
      </Box>
    );
  }, [finalDotActive, finalSlideIndex, isFinalSlide, setFinalDotActive, slice]);

  return (
    <Box className={styles.container} ref={ref}>
      <section
        id={`slice-${slice.primary.stepNumber}`}
        className={classNames(styles.section, {
          [styles.slideWithLinks]: !isDefault,
        })}
      >
        <Box display="flex">
          <Box width="100%">
            <Grid container alignItems="center" className={styles.slide}>
              {image(state)}
              {!(isFinalSlide && isTablet) && title(state)}
            </Grid>
          </Box>

          {!isTablet && steps()}
        </Box>
      </section>

      {(belowSlideRendered || (!isDefault && !hijackScroll)) && (
        <section
          className={classNames(styles.linksPage, {
            [styles.linksPageShown]: belowSlideOpacity || !hijackScroll,
          })}
          ref={linksPageRef}
        >
          {links(state)}
        </section>
      )}
    </Box>
  );
};

export default HomePage;
