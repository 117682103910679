import { Box, Button, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React, { useState, FC, Fragment, useCallback } from 'react';
import { HorizontalLine, SummaryCard, UserBioPanel } from '../../components';
import styles from './TeamMemberGrid.module.scss';
import { chunk, useIsMobile } from '../../utils/helpers';
import { PrismicLink } from '@prismicio/react';
import { SRW_FONT_WIDER } from '../../theme/fonts';

// need this so it can be referenced in SliceZone
type Props = { slice: any; context: { team: any[] }};

interface User {
  id: string;
  data: {
    firstName: string;
    snack?: string;
    game?: string;
    hobbies?: string;
    app?: string;
    superPower?: string;
  };
}

// placeholder to make a grid of the team members
const TeamMember: FC<Props> = (props) => {
  const [show, setShow] = useState<boolean>(false);
  const [activeUser, setActiveUser] = useState<User>();
  const [activeRow, setActiveRow] = useState<number>(0);
  const isMobile = useIsMobile();
  const isTablet = useIsMobile('md');
  const isDesktop = useIsMobile('lg');

  const { team } = props.context;

  const elementsPerRow = !isDesktop ? 4 : 3;

  const HiringCard: FC = useCallback(() => (
    <Box
      display="flex"
      width="100%"
      minHeight="80vw"
      className={styles.hiring}
      flexDirection="column"
      alignItems="space-between"
      justifyContent="center"
    >
      <Box textAlign="center">
        <Typography variant="h4">
          Join us
          <br />
          we&apos;re hiring!
        </Typography>
      </Box>
      <Box mt={3} mb={1}>
        <HorizontalLine accent center />
      </Box>
      <Box textAlign="center">
        <PrismicLink href='/careers'>
          <Button
            variant="outlined"
            color="inherit"
            size="small"
            className={styles.button}
            sx={{ letterSpacing: SRW_FONT_WIDER }}
          >
            view positions
          </Button>
        </PrismicLink>
      </Box>
    </Box>
  ), []);

  if (isTablet) {
    return (
      <>
        <section className={styles.section}>
          <Box className={styles.grid}>
            {team?.length &&
              team.map((member: any) => (
                <Fragment key={member.uid || 'hiring'}>
                  {!member.hiring ? (
                    <Box
                      onClick={() => {
                        setActiveUser(() => {
                          if (!show) {
                            setShow(true);
                          } else {
                            const isVisible = activeUser
                              ? member.id !== activeUser.id
                              : true;
                            setShow(isVisible);
                          }

                          return member;
                        });
                      }}
                    >
                      <SummaryCard
                        imageUrl={member.data.image.url}
                        title={`${member.data.firstName} ${member.data.surname}`}
                        subtitle={member.data.position}
                      />
                    </Box>
                  ) : (
                    !isMobile && <HiringCard />
                  )}
                </Fragment>
              ))}
          </Box>
          {isMobile && <HiringCard />}
        </section>
        {activeUser && (
          <Dialog
            fullScreen={true}
            maxWidth={false}
            open={show}
            onClose={() => setShow(false)}
            sx={{ marginTop: '20vh' }}
          >
            <DialogContent>
              <UserBioPanel user={activeUser} onClose={() => setShow(false)} />
            </DialogContent>
          </Dialog>
        )}
      </>
    );
  }

  return (
    <section className={styles.section}>
      {team?.length && chunk(team, elementsPerRow).map((row, rowNum) => (
        <React.Fragment key={rowNum.toString()}>
          <Box className={styles.grid}>
            {row?.length &&
              row.map((member: any) => (
                <Fragment key={member.uid || 'hiring'}>
                  {!member.hiring ? (
                    <Box
                      onClick={() => {
                        setActiveRow(rowNum);
                        setActiveUser(() => {
                          if (!show) {
                            setShow(true);
                          } else {
                            const isVisible = activeUser
                              ? member.id !== activeUser.id
                              : true;
                            setShow(isVisible);
                          }

                          return member;
                        });
                      }}
                    >
                      <SummaryCard
                        imageUrl={member.data.image.url}
                        title={`${member.data.firstName} ${member.data.surname}`}
                        subtitle={member.data.position}
                      />
                    </Box>
                  ) : (
                    <HiringCard />
                  )}
                </Fragment>
              ))}
          </Box>
          {show && activeUser && activeRow === rowNum && (
            <UserBioPanel
              user={activeUser}
              onClose={() => {
                setShow(false);
              }}
            />
          )}
        </React.Fragment>
      ))}
    </section>
  );
};

export default TeamMember;
