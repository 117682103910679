import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './Blockquote.module.scss';
import { Box } from '@mui/material';
import { RichText } from '../../components';

type Props = { slice: any };

const Blockquote: FC<Props> = ({ slice }) => {
  const isDarkMode = slice.variation.endsWith('dark');

  const hasTitle =
    !!slice.primary.title &&
    slice.primary.title.length > 0 &&
    !!slice.primary.title[0].text;

  const hasText = slice.primary.text && slice.primary.text.length > 0;

  return (
    <div
      className={classNames({
        dark: isDarkMode,
        light: !isDarkMode, // only to control if margins should be removed
      })}
    >
      <section className={styles.section}>
        <Box className={styles.bar}></Box>
        <Box className={styles.content}>
          {hasTitle && (
            <Box className={styles.title}>
              <RichText field={slice.primary.title} />
            </Box>
          )}
          {hasText && (
            <Box className={styles.text}>
              <RichText field={slice.primary.text} />
            </Box>
          )}
        </Box>
      </section>
    </div>
  );
};

export default Blockquote;
