import React, { FC } from 'react';
import { Box } from '@mui/material';
import { Splide, SplideSlide, SplideProps } from '@splidejs/react-splide';
import { CarouselControls, SRWImage } from '../../components';
import classNames from 'classnames';
import '@splidejs/splide/dist/css/splide.min.css';
import { useIsMobile } from '../../utils/helpers';

type Props = { slice: any };

type CarouselOptions = Pick<SplideProps, 'options'>;

const getCarouselOptions = (
  mobile: boolean,
  tablet: boolean
): CarouselOptions => {
  let opts = {
    options: {
      type: 'loop',
      fixedHeight: 276,
      fixedWidth: 234,
      pagination: false,
      cover: true,
      gap: 16,
      padding: 0,
    },
  };
  if (mobile) {
    return opts;
  }
  if (tablet) {
    return {
      options: {
        ...opts.options,
        fixedHeight: 402,
        fixedWidth: 486,
        gap: 20,
        padding: 40,
      },
    };
  }
  return {
    options: {
      ...opts.options,
      fixedHeight: 520,
      fixedWidth: 760,
      gap: 24,
      padding: 80,
    },
  };
};

const ImageCarousel: FC<Props> = ({ slice }) => {
  const isDarkMode = slice.variation?.endsWith('dark') ?? false;
  const mobile = useIsMobile();
  const tablet = useIsMobile('md');
  const { options } = getCarouselOptions(mobile, tablet);

  return (
    <Box
      className={classNames('carousel', {
        dark: isDarkMode,
      })}
    >
      <Splide
        options={options}
        renderControls={() => (
          <div className="splide__arrows">
            <CarouselControls
              size={mobile ? 'small' : 'large'}
              leftArrowClasses={['splide__arrow', 'splide__arrow--prev']}
              rightArrowClasses={['splide__arrow', 'splide__arrow--next']}
            />
          </div>
        )}
      >
        {slice.items.map((item: any, i: number) => (
          <SplideSlide key={i}>
            {
              <SRWImage
                image={item.image}
                type="cover"
                minHeight={{ xs: '276px', sm: '402px', md: '520px' }}
              />
            }
          </SplideSlide>
        ))}
      </Splide>
    </Box>
  );
};

export default ImageCarousel;
