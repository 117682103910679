import { Box, Button, Grid } from '@mui/material';
import { PrismicLink } from '@prismicio/react';
import React, { FC } from 'react';
import { HorizontalLine, RichText, SRWImage } from '../../components';
import classNames from 'classnames';

type Props = { slice: any };

const ImageText: FC<Props> = ({ slice }) => {
  const isDarkMode = slice.variation.endsWith('dark');
  const isRight = slice.variation.includes('right');

  return (
    <div
      className={classNames('wide', {
        dark: isDarkMode,
      })}
    >
      <section>
        <Grid
          container
          py={{ xs: 2, sm: 4, md: 8 }}
          spacing={{ xs: 0, sm: 2 }}
          direction={isRight ? 'row-reverse' : 'row'}
          className="content"
          display="flex"
          alignItems="center"
          mx="auto"
        >
          <Grid item xs={12} sm={6} py={{ xs: 2, sm: 0 }}>
            <Box minHeight={{ xs: '471px', sm: '642px' }}>
              {(slice.primary.image && slice.primary.image.url) && (
                <SRWImage
                  image={slice.primary.image}
                  type={slice.primary.imageContains ? undefined : 'cover'} // contains is default
                  minHeight={{ xs: '471px', sm: '642px' }}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              pl={{ xs: 0, sm: !isRight ? 8 : 0, md: !isRight ? 14 : 0 }}
              pr={{ xs: 0, sm: isRight ? 8 : 0, md: isRight ? 14 : 0 }}
            >
              {(slice.primary.title || slice.primary.subtitle) && (
                <Box>
                  {slice.primary.title && (
                    <RichText field={slice.primary.title} />
                  )}
                  {slice.primary.subtitle && (
                    <RichText field={slice.primary.subtitle} />
                  )}
                  <HorizontalLine />
                </Box>
              )}
              {slice.primary.text && <RichText field={slice.primary.text} />}
              {slice.primary.label &&
                slice.primary.link &&
                slice.primary.link.url && (
                  <Box mt={{ xs: 4, md: 8 }}>
                    <PrismicLink href={slice.primary.link.url}>
                      <Button
                        variant="outlined"
                        color={isDarkMode ? 'secondary' : 'primary'}
                      >
                        {slice.primary.label}
                      </Button>
                    </PrismicLink>
                  </Box>
                )}
            </Box>
          </Grid>
        </Grid>
      </section>
    </div>
  );
};

export default ImageText;
