import React, { FC, Fragment } from 'react';
import { PrismicLink, PrismicText } from '@prismicio/react';
import classNames from 'classnames';
import { Box, Button, Typography } from '@mui/material';
import { SRWImage, RichText, SRWIcon, isValidIcon } from '../../components';
import styles from './CardLine.module.scss';
import { SRW_COLOR_PRIMARY } from '../../theme';
import { useIsMobile } from '../../utils/helpers';
import { SRW_FONT_WIDER } from '../../theme/fonts';

type Props = { slice: any };

const CtaCardLine: FC<Props> = ({ slice }) => {
  const isMobile = useIsMobile();
  const isTablet = useIsMobile('md');
  const isDarkMode = slice.variation.endsWith('dark');

  const columns = slice.primary.columns;
  const columnsOnTablet =
    columns > 2 && columns % 2 === 0 ? columns / 2 : columns;
  const darkCards = slice.primary.darkCards;
  const noCards = slice.primary.noCards;
  const center = slice.primary.center ?? true;
  const denseCards = slice.primary.denseCards;

  const spacing = denseCards ? { xs: 2, md: 3 } : { xs: 4, md: 6 };

  function cardRow(items: any[]) {
    return (
      <Fragment>
        {items.map((card: any, index: number) => (
          <Box
            key={`cta-card-${index}`}
            className={classNames({
              [styles.card]: !noCards && !darkCards,
              [styles.darkCard]: darkCards,
            })}
            display="grid"
            textAlign={center ? 'center' : undefined}
            justifyContent={center ? 'center' : undefined}
            gap={spacing}
            py={denseCards ? 3 : 5}
            px={{ xs: 2, md: 3 }}
            m={1.5}
          >
            {card.image && card.image.url && (
              <Box>
                <SRWImage image={card.image} minHeight="75px" />
              </Box>
            )}
            {card.icon && isValidIcon(card.icon) && (
              <Box
                display="flex"
                justifyContent={center ? 'center' : undefined}
              >
                <Box
                  width="fit-content"
                  height="fit-content"
                  className={classNames(styles.icon, {
                    [styles.darkCard]: darkCards,
                    [styles.noCard]: noCards,
                  })}
                >
                  <SRWIcon
                    size={card.iconSize || '2x'}
                    color={!darkCards ? SRW_COLOR_PRIMARY : undefined}
                    name={card.icon}
                  />
                </Box>
              </Box>
            )}
            {card.title && card.title.length > 0 && (
              <Box>
                <RichText field={card.title} />
              </Box>
            )}
            {card.text && (
              <Box>
                <Typography
                  variant={columns > 2 && !isTablet ? 'body2' : 'body1'}
                >
                  <PrismicText field={card.text} />
                </Typography>
              </Box>
            )}
            {card.label && card.url && (
              <Box mt="auto">
                <PrismicLink href={card.url}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    sx={{ letterSpacing: SRW_FONT_WIDER }}
                  >
                    {card.label}
                  </Button>
                </PrismicLink>
              </Box>
            )}
          </Box>
        ))}
      </Fragment>
    );
  }

  return (
    <div
      className={classNames('wide', {
        dark: isDarkMode,
      })}
    >
      <section>
        {!columns ? (
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignContent="center"
            justifyContent="space-between"
            maxWidth="1156px"
            py={{ xs: 2, sm: 3, md: 5 }}
          >
            {cardRow(slice.items)}
          </Box>
        ) : (
          <Fragment>
            {isMobile ? (
              <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignContent="center"
                justifyContent="space-between"
                maxWidth="1156px"
                py={{ xs: 2, sm: 3, md: 5 }}
              >
                {cardRow(slice.items)}
              </Box>
            ) : (
              <Box
                display="grid"
                sx={{
                  gridTemplateColumns: `repeat(${
                    isTablet ? columnsOnTablet : columns
                  }, 1fr)`,
                }}
                alignContent="center"
                justifyContent="space-between"
                maxWidth="1156px"
                py={{ xs: 2, sm: 3, md: 5 }}
              >
                {cardRow(slice.items)}
              </Box>
            )}
          </Fragment>
        )}
      </section>
    </div>
  );
};

export default CtaCardLine;
