import { Box, Typography } from '@mui/material';
import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { SRWImage, HorizontalLine, RichText } from '../../components';
import { useScrollPosition } from '../../hooks/useScrollPosition';
import { useIsMobile } from '../../utils/helpers';
import styles from './HowWeWork.module.scss';

type LineProps = { index: number };

const MobileLine: FC<LineProps> = ({ index }) => {
  const scrollArea = useRef();
  const [position, setPosition] = useState<number | undefined>(undefined);
  const [height, setHeight] = useState<number | undefined>(undefined);
  const [lower, setLower] = useState<number | undefined>(undefined);
  const [higher, setHigher] = useState<number | undefined>(undefined);
  const [scrollHeight, setScrolllHeight] = useState<number | undefined>(
    undefined
  );

  const updateDimensions = useCallback(() => {
    const current: HTMLDivElement | undefined = scrollArea.current as
      | HTMLDivElement
      | undefined;
    if (!!current) {
      setHeight(current.scrollHeight);
      setLower(Math.round(current.offsetTop - (window.innerHeight / 3) * 2));
      setHigher(
        Math.round(
          current.offsetTop + current.scrollHeight - window.innerHeight / 3
        )
      );
      setScrolllHeight(
        Math.round(current.scrollHeight + window.innerHeight / 3)
      );
    }
  }, [scrollArea]);

  useEffect(() => {
    const resizeHandler = () => {
      updateDimensions();
    };

    resizeHandler();
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [updateDimensions]);

  useEffect(() => {
    updateDimensions();
  }, [updateDimensions]);

  useScrollPosition(
    ({ currPos }) => {
      if (lower && higher && scrollHeight && height) {
        if (currPos.y > lower && currPos.y < higher) {
          setPosition(
            Math.round(((currPos.y - lower) / scrollHeight) * height)
          );
        } else {
          setPosition(undefined);
        }
      }
    },
    [scrollArea, lower, higher, scrollHeight, height],
    scrollArea ?? undefined,
    true,
    50
  );

  return (
    <Box
      className={styles.line}
      height={index === 0 ? '110%' : '120%'}
      width="5px"
      mt={index === 0 ? 0 : -4}
      ref={scrollArea}
    >
      {!!position && (
        <Box
          position="relative"
          className={styles.ball}
          top={position}
          left="-13px"
        />
      )}
    </Box>
  );
};

const DesktopLine: FC<LineProps> = ({ index }) => {
  const scrollArea1 = useRef();
  const scrollArea2 = useRef();
  const scrollArea3 = useRef();
  const isLeft = index % 2 === 0;
  const [position1, setPosition1] = useState<number | undefined>(undefined);
  const [height1, setHeight1] = useState<number | undefined>(undefined);
  const [lower1, setLower1] = useState<number | undefined>(undefined);
  const [higher1, setHigher1] = useState<number | undefined>(undefined);
  const [scrollHeight1, setScrolllHeight1] = useState<number | undefined>(
    undefined
  );
  const [position2, setPosition2] = useState<number | undefined>(undefined);
  const [width2, setWidth2] = useState<number | undefined>(undefined);
  const [scrollHeight2, setScrolllHeight2] = useState<number | undefined>(
    undefined
  );
  const [position3, setPosition3] = useState<number | undefined>(undefined);
  const [height3, setHeight3] = useState<number | undefined>(undefined);
  const [lower3, setLower3] = useState<number | undefined>(undefined);
  const [higher3, setHigher3] = useState<number | undefined>(undefined);
  const [scrollHeight3, setScrolllHeight3] = useState<number | undefined>(
    undefined
  );

  const updateDimensions = useCallback(() => {
    const current1: HTMLDivElement | undefined = scrollArea1.current as
      | HTMLDivElement
      | undefined;
    const current2: HTMLDivElement | undefined = scrollArea2.current as
      | HTMLDivElement
      | undefined;
    const current3: HTMLDivElement | undefined = scrollArea3.current as
      | HTMLDivElement
      | undefined;
    const third = Math.round(window.innerHeight / 3);
    const half = Math.round(window.innerHeight / 2);
    const sixth = Math.round(window.innerHeight / 6);
    // first downward leg
    if (!!current1) {
      setHeight1(current1.scrollHeight - 10);
      setLower1(current1.offsetTop - sixth * 5);
      setHigher1(current1.offsetTop - half);
      setScrolllHeight1(third);
    }
    // horizonal leg
    if (!!current1 && !!current2 && !!current3) {
      setWidth2(current2.scrollWidth - 10);
      setScrolllHeight2(
        current3.offsetTop + current3.scrollHeight - current1.offsetTop
      );
    }
    // final downward leg
    if (!!current3) {
      setHeight3(current3.scrollHeight - 10);
      setLower3(current3.offsetTop + current3.scrollHeight - half);
      setHigher3(current3.offsetTop + current3.scrollHeight - sixth);
      setScrolllHeight3(third);
    }
  }, [scrollArea1, scrollArea2, scrollArea3]);

  useEffect(() => {
    const resizeHandler = () => {
      updateDimensions();
    };

    resizeHandler();
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [updateDimensions]);

  useEffect(() => {
    updateDimensions();
  }, [updateDimensions]);

  useScrollPosition(
    ({ currPos }) => {
      if (lower1 && higher1 && scrollHeight1 && height1) {
        if (currPos.y > lower1 && currPos.y < higher1) {
          setPosition1(
            Math.round(((currPos.y - lower1) / scrollHeight1) * height1)
          );
        } else {
          setPosition1(undefined);
        }
      }
    },
    [scrollArea1, index, isLeft, lower1, higher1, scrollHeight1, height1],
    scrollArea1 ?? undefined,
    true,
    50
  );

  useScrollPosition(
    ({ currPos }) => {
      if (higher1 && lower3 && scrollHeight2 && width2) {
        if (currPos.y >= higher1 && currPos.y <= lower3) {
          const position = Math.round(
            ((currPos.y - higher1) / scrollHeight2) * width2
          );
          setPosition2(isLeft ? width2 - position : position);
        } else {
          setPosition2(undefined);
        }
      }
    },
    [scrollArea2, index, isLeft, higher1, lower3, scrollHeight2, width2],
    scrollArea2 ?? undefined,
    true,
    50
  );

  useScrollPosition(
    ({ currPos }) => {
      if (lower3 && higher3 && scrollHeight3 && height3) {
        if (currPos.y > lower3 && currPos.y < higher3) {
          setPosition3(
            Math.round(((currPos.y - lower3) / scrollHeight3) * height3)
          );
        } else {
          setPosition3(undefined);
        }
      }
    },
    [scrollArea3, index, isLeft, lower3, higher3, scrollHeight3, height3],
    scrollArea3 ?? undefined,
    true,
    50
  );

  return (
    <Box
      display="flex"
      ml={2}
      flexDirection="column"
      className={styles.desktopBox}
    >
      <Box
        className={styles.line}
        width="5px"
        height="min(120px, 20vh)"
        position="relative"
        left={{ sm: isLeft ? '54vw' : 0, md: isLeft ? '642px' : 0 }}
        ref={scrollArea1}
      >
        {!!position1 && (
          <Box
            position="relative"
            className={styles.ball}
            top={position1}
            left="-13px"
          />
        )}
      </Box>
      <Box
        className={styles.line}
        height="5px"
        width={{ sm: 'calc(54vw + 5px)', md: '647px' }}
        position="relative"
        ref={scrollArea2}
      >
        {!!position2 && (
          <Box
            position="relative"
            className={styles.ball}
            left={position2}
            top="-13px"
          />
        )}
      </Box>
      <Box
        className={styles.line}
        width="5px"
        height="min(165px, 20vh)"
        position="relative"
        left={{ sm: !isLeft ? '54vw' : 0, md: !isLeft ? '642px' : 0 }}
        ref={scrollArea3}
      >
        {!!position3 && (
          <Box
            position="relative"
            className={styles.ball}
            top={position3}
            left="-13px"
          />
        )}
      </Box>
    </Box>
  );
};

type Props = { slice: any };

const HowWeWork: FC<Props> = ({ slice }) => {
  const isMobile = useIsMobile();
  const lastStep = slice.items.length - 1;
  return (
    <Box component="section" mb={{ xs: 3, sm: 5 }}>
      {slice.items &&
        slice.items.map((item: any, index: number) => (
          <Fragment key={`step-${index}`}>
            <Box
              display="flex"
              flexDirection={{
                xs: 'column',
                sm: index % 2 === 0 ? 'row' : 'row-reverse',
              }}
              alignItems="center"
              justifyContent="space-between"
            >
              {item.image && (
                <SRWImage
                  image={item.image}
                  minHeight={{ xs: '50vh', sm: '60vh' }}
                  minWidth={{ xs: '80vw', sm: '50vw', md: '578px' }}
                />
              )}
              <Box display="flex" flexDirection="row">
                {isMobile && (
                  <Box>
                    <MobileLine index={index} />
                  </Box>
                )}
                <Box ml={{ xs: 3, sm: index % 2 === 0 ? 8 : 0 }}>
                  <Typography variant="h1" component="h2" fontWeight="medium">
                    0{index + 1}
                  </Typography>
                  <Box>
                    {item.title && (
                      <Fragment>
                        <RichText field={item.title} />
                        <HorizontalLine />
                      </Fragment>
                    )}
                    {item.text && <RichText field={item.text} />}
                  </Box>
                </Box>
              </Box>
            </Box>
            {index !== lastStep && !isMobile && (
              <Box py={4}>
                <DesktopLine index={index} />
              </Box>
            )}
          </Fragment>
        ))}
    </Box>
  );
};
export default HowWeWork;
