import React, { FC } from 'react';
import { RichText } from '../../components';
import styles from './TestTitle.module.scss';

type Props = { slice: any };

const TestTitle: FC<Props> = ({ slice }) => (
  <section className={styles.section}>
    <span className={styles.title}>
      {slice.primary.title ? (
        <RichText field={slice.primary.title} />
      ) : (
        <h2>Template slice, update me!</h2>
      )}
    </span>
    {slice.primary.description ? (
      <RichText field={slice.primary.description} />
    ) : (
      <p>start by editing this slice from inside Prismic builder!</p>
    )}
  </section>
);

export default TestTitle;
